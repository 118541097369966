import { PagerType, initPagerType } from "@/shared/types";

/**
 * Tipo para item de Dedução
 *
 * @typedef {Object} DeductionRule
 * @property {number} id - ID da dedução
 * @property {string} uuid - UUID da dedução
 * @property {string} date_at - Data da dedução
 * @property {string} rule - Regra de dedução
 * @property {string} base - Base da dedução
 * @property {?string} condition - Condição da dedução
 * @property {boolean} deduct_mcc_cost - Deduz custo MCC
 * @property {boolean} consider_reuse - Considera reutilização
 * @property {?string} observation - Observação
 * @property {string} tax_locality_uuid - UUID da localidade fiscal
 * @property {string} deduct - Valor da dedução
 * @property {string} total_rate - Taxa total
 * @property {string} retained_rate - Taxa retida
 * @property {number} created_by_user_id - ID do usuário criador
 * @property {?number} updated_by_user_id - ID do usuário atualizador
 * @property {string} issuer_regime - Regime do emissor
 * @property {string} name - Nome da dedução
 * @property {string} construction_city - Cidade da construção
 * @property {number} full_coverage - Cobertura total
 * @property {number} allow_issqn_retention_of_legal_person - Permite retenção de ISSQN para pessoa jurídica
 * @property {number} allow_issqn_retention_of_natural_person - Permite retenção de ISSQN para pessoa física
 * @property {number} allow_issuance_without_customer_municipal_registration - Permite emissão sem inscrição municipal do cliente
 */

/**
 * Tipo para item da listagem de faturas
 *
 * @typedef {Object} InvoiceListType
 * @property {number} id - ID da fatura
 * @property {string} uuid - UUID da fatura
 * @property {string} code - Código da fatura
 * @property {?string} billing_guidelines - Diretrizes de faturamento
 * @property {number} company_plant_issuer_id - ID da planta emissora da empresa
 * @property {string} construction_name - Nome da construção
 * @property {string} entity_name - Nome da entidade
 * @property {string} document - Documento da entidade
 * @property {number} entity_id - ID da entidade
 * @property {string} city - Cidade
 * @property {string} state - Estado
 * @property {string} customer_name - Nome do cliente
 * @property {string} balance - Saldo
 * @property {number} company_plant_id - ID da planta da empresa
 * @property {string} company_plant_name - Nome da planta da empresa
 * @property {?string} pending - Status de pendência
 * @property {string} seller - Nome do vendedor
 * @property {string} tax_regime - Regime tributário
 * @property {0|1} nfse_rule1_equal_construction_default - Regra 1 da NFS-e (construção padrão)
 * @property {0|1} nfse_rule1_within_city_iss_legal - Regra 1 da NFS-e (ISS legal dentro da cidade)
 * @property {0|1} nfse_rule1_within_city_iss_individual - Regra 1 da NFS-e (ISS individual dentro da cidade)
 * @property {0|1} nfse_rule1_outside_city_iss_legal - Regra 1 da NFS-e (ISS legal fora da cidade)
 * @property {0|1} nfse_rule1_outside_city_iss_individual - Regra 1 da NFS-e (ISS individual fora da cidade)
 * @property {0|1} nfse_rule1_within_city_esp - Regra 1 da NFS-e (específico dentro da cidade)
 * @property {0|1} nfse_rule1_within_city_iss_legal_esp - Regra 1 da NFS-e (ISS legal específico dentro da cidade)
 * @property {0|1} nfse_rule1_within_city_iss_individual_esp - Regra 1 da NFS-e (ISS individual específico dentro da cidade)
 * @property {0|1} nfse_rule2_diff_construction_default - Regra 2 da NFS-e (construção padrão diferenciada)
 * @property {0|1} nfse_rule2_within_city_iss_legal - Regra 2 da NFS-e (ISS legal dentro da cidade)
 * @property {0|1} nfse_rule2_within_city_iss_individual - Regra 2 da NFS-e (ISS individual dentro da cidade)
 * @property {0|1} nfse_rule2_outside_city_iss_legal - Regra 2 da NFS-e (ISS legal fora da cidade)
 * @property {0|1} nfse_rule2_outside_city_iss_individual - Regra 2 da NFS-e (ISS individual fora da cidade)
 * @property {0|1} nfse_rule2_outside_city_esp - Regra 2 da NFS-e (específico fora da cidade)
 * @property {0|1} nfse_rule2_outside_city_iss_legal_esp - Regra 2 da NFS-e (ISS legal específico fora da cidade)
 * @property {0|1} nfse_rule2_outside_city_iss_individual_esp - Regra 2 da NFS-e (ISS individual específico fora da cidade)
 * @property {string} company_plant_issuer_addresses_city - Cidade do endereço da planta emissora
 * @property {number} bank_account_id - ID da conta bancária
 * @property {string} address - Endereço
 * @property {string} number - Número do endereço
 * @property {string} postal_code - Código postal
 * @property {string} entity_document - Documento da entidade
 * @property {string} entity_addresses_address - Endereço da entidade
 * @property {string} entity_addresses_number - Número do endereço da entidade
 * @property {string} entity_addresses_postal_code - Código postal do endereço da entidade
 * @property {string} entity_addresses_district - Bairro do endereço da entidade
 * @property {string} entity_addresses_city - Cidade do endereço da entidade
 * @property {string} entity_addresses_state - Estado do endereço da entidade
 * @property {string} payment_methods - Métodos de pagamento
 * @property {string} payment_terms - Termos de pagamento
 * @property {string} bank_accounts - Contas bancárias
 * @property {number} construction_id - ID da construção
 * @property {boolean} has_pending_service - Indica se há serviço pendente
 * @property {boolean} has_pending_travels - Indica se há viagens pendentes
 * @property {boolean} allow_use_anticipation_balance - Permite uso de saldo de antecipação
 * @property {boolean} use_anticipation_balance - Utiliza saldo de antecipação
 * @property {string} key - Chave única
 * @property {boolean} is_pending - Indica se está pendente
 * @property {boolean} iss_retain - Retenção de ISS
 * @property {?number} payment_term_id - ID do termo de pagamento
 * @property {?number} payment_intermediary_id - ID do intermediário de pagamento
 * @property {?number} payment_method_id - ID do método de pagamento
 * @property {boolean} block_iss - Bloqueio de ISS
 * @property {DeductionRule} deduction - Dados de dedução
 * @property {boolean} has_deduct - Indica se há dedução
 * @property {string} has_deduct_title - Título da dedução
 * @property {number} cofins_rate - Taxa de COFINS
 * @property {boolean} cofins_retained_flag - Indicador de retenção de COFINS
 * @property {number} csll_rate - Taxa de CSLL
 * @property {boolean} csll_retained_flag - Indicador de retenção de CSLL
 * @property {number} inss_rate - Taxa de INSS
 * @property {boolean} inss_retained_flag - Indicador de retenção de INSS
 * @property {number} ir_rate - Taxa de IR
 * @property {boolean} ir_retained_flag - Indicador de retenção de IR
 * @property {number} pis_rate - Taxa de PIS
 * @property {boolean} pis_retained_flag - Indicador de retenção de PIS
 */

export const InvoiceListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<InvoiceListType> } )} InvoiceListPagerType
 */
export const InvoiceListPagerType = {};

/**
 * Inicializar InvoiceListPagerType
 *
 * @returns {InvoiceListPagerType}
 */
export const initInvoiceListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

