import store from "../../../../../shared/libraries/store";
import { initInvoiceListPagerType, InvoiceListFilterType, InvoiceListPagerType, InvoiceType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../shared/store";

/** URLS */
const urls = Object.freeze({
  BASE: "/commercial/contract-proposal/pending-billing+microservice",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "INVOICE_ITEM",
  LISTED: "INVOICE_LISTED",
  IS_LISTING: "INVOICE_IS_LISTING",
  IS_FINDING: "INVOICE_IS_FINDING",
  IS_SAVING: "INVOICE_IS_SAVING",
  IS_REMOVING: "INVOICE_IS_REMOVING",
  PAGE_STATES: "INVOICE_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?InvoiceType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {InvoiceListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initInvoiceListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: InvoiceListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {InvoiceType} item
   * @returns {InvoiceType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {InvoiceListPagerType} listed
   * @returns {InvoiceListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {InvoiceType} created
   * @returns {InvoiceType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {InvoiceListPagerType} listed
   * @returns {InvoiceListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {InvoiceType} updated
   * @returns {InvoiceType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: InvoiceListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {InvoiceType} data
   * @returns {Promise<InvoiceType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?InvoiceType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {InvoiceListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?InvoiceListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {InvoiceType} data
   * @param {number} id
   * @returns {Promise<InvoiceType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),
}

export default {
  actions,
  getters,
  mutations,
};
