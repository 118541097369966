<template>
  <div>
    <AppFixedPageTitle
      title="Em Aberto"
      icon="/img/icons/icons8/ios/check.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="$_invoices_is_listing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <!-- Esse trecho de filtros será usado no futuro -->
          <!-- <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Vendedor
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.seller_id"
                :items="[]"
                :disableBoxShadow="true"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-12 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Valor da Fatura
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric"
                      v-money="money"
                      v-model.lazy="filter.range_invoice_value.min"
                      placeholder="de"
                    />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric"
                      v-money="money"
                      v-model.lazy="filter.range_invoice_value.max"
                      placeholder="até"
                    />
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.nfse_pending_issue = Number(!filter.nfse_pending_issue)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-danger': filter.nfse_pending_issue}"
              >
                <img
                  height="15"
                  src="/img/icons/icons8/ios/error--v1_danger.png"
                  style="margin-right: 10px;"
                >
                  Avisos
              </button>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.verify_status = Number(!filter.verify_status)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-danger': filter.verify_status}"
              >
                <img
                  height="15"
                  src="/img/icons/icons8/ios/break--v2_danger.png"
                  style="margin-right: 10px;"
                >
                  OBSERVAÇÕES PARA FATURAMENTO
              </button>
            </div>
          </AppSearchBarFilterSection> -->
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            :text="!invoice ? 'Faturar em lote' : 'Canc. Faturamento'"
            :type="!invoice ? 'outline-success' : 'danger'"
            @click.prevent="handleInvoice"
            :icon="!invoice ? '/img/icons/icons8/ios/choose_success.png' : '/img/icons/icons8/ios/choose.png'"
          />
          <AppPageHeaderActionsButton
            text="Avulso"
            type="primary"
            @click.prevent="handleModalSearchContract"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            text="Relatório"
            type="dark"
            @click.prevent="handleShowModalReport"
            icon="/img/icons/icons8/ios/graph-report.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
      :isShowViewTypes="false"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    {{ logIt($_invoices_listed) }}
<!--    <modal-individual @agrouped="handleClickAgroup" @closeAndUpdateInvoiced="handleCloseModalIndividual"-->
<!--                      ref="modalIndividual"/>-->
<!--    <modal-federal-taxes ref="modalFederalTaxes"/>-->
<!--    <ModalBilling ref="modalBilling"/>-->
<!--    <deduction-rule-creation-modal ref="DeductionRuleCreationModal" :updateObject="updateObject"/>-->
<!--    <modal-search-contract @openCreateSingle="showModalCreateSingle" ref="modalSearchContract"/>-->
<!--    <create-single-->
<!--      :contractProposalId="contractProposalId"-->
<!--      :contractProposalCode="contractProposalCode"-->
<!--      :constructionName="constructionName"-->
<!--      :customerConstructionName="customerConstructionName"-->
<!--      @fetch="fetch"-->
<!--      ref="createSingle"-->
<!--    />-->
<!--    <ModalReport ref="modalReport"/>-->
  </div>
</template>
<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import invoiceStore from "../store/invoiceStore";
import { initInvoiceListFilterType, InvoiceListFilterType } from '../types';
import { date } from "../../../../../helpers";
import { dialogs } from '../../../../../helpers';
import InvoiceListPageCards from './list/InvoiceListPageCards.vue';
import InvoiceListPageTable from './list/InvoiceListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import { Ref } from 'vue';
//#endregion

//#region ComponentRefs

//#endregion

//#region Data
/** type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** type {InvoiceListFilterType} - Filtro principal */
const filter = reactive(initInvoiceListFilterType());

// Tipo de listagem que será feita
const listType = ref('cards');

// Habilita/Desabilita seleção para faturar em lote
const invoice = ref(false);

// Quantidade de faturas faturadas
const invoiced = ref(0);

// Inicializa a busca por faturas em aberto do dia atual
const currentDate = ref(date.make().format(date.FORMAT.ISO_8601));

const orderBy = reactive({
  selected: 0,
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [],
    },
    {
      id: 1,
      name: "A-Z",
      selected_name: "A-Z",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "code",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "Z-A",
      selected_name: "Z-A",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "code",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "LANÇAMENTO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "LANÇ. MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "id",
        is_desc: true,
      }],
    },
    {
      id: 4,
      name: "LANÇAMENTO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "LANÇ. MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "id",
        is_desc: false,
      }],
    },
  ]
});
//#endregion

//#region Computeds
/** Store Getters */
const $_invoices_listed = computed(() => invoiceStore.getters.getListed());
const $_invoices_is_listing = computed(() => invoiceStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.by_measurement === null,
    },
    {
      id: 0,
      name: 'Diário',
      selected: filter.by_measurement === 0,
    },
    {
      id: 1,
      name: 'Por medição',
      selected: filter.by_measurement === 1,
    },
  ]
});
//#endregion

//#region Methods

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  const tabActions = {
    null: () => {
      filter.by_measurement = null;
    },
    0: () => {
      filter.by_measurement = 0;
    },
    1: () => {
      filter.by_measurement = 1;
    }
  };

  if (filter.by_measurement !== item.id) {
    tabActions[item.id]?.();
    listItems();
  }
}

/**
 *
 * @param {String} viewType - Modifica tipo de listagem {"cards"|"table"}
 */
function handleViewTypeChange(viewType) {
  listType.value = viewType;
}

/**
 *
 * @param {Boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilter = withStoreFilters ? invoiceStore.getters.getPageState() : null;
  if(storeFilter) {
    searchBarFilterValue = storeFilter.searchBarFilter;
    filterValue = storeFilter.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if(isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    filter: {
      columns: [],
      values: [],
    },
    range: {
      items: [],
      selected: null,
      start: currentDate,
      end: currentDate,
    },
  }
}

/**
 * Padrão do filtro principal
 * @returns {InvoiceListFilterType}
 */
function defaultFilter() {
  return initInvoiceListFilterType();
}

/**
 * Preparar filtro para modelo esperado pelo endpoint
 */
function prepareFilter() {
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.global = searchBarFilter.custom_search_values;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;

  const selectedItem = orderBy.items[orderBy.selected];
  const selectedFilter = selectedItem.filter.length ? selectedItem.filter[0] : null;

  filter.order_by = selectedFilter
    ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
    : "";
}

/**
 *
 * @param {Boolean} isAccumulateItems - Indica se deve acumular itens ou fazer nova pesquisa
 */
function listItems(isAccumulateItems = true) {
  prepareFilter();
  invoiceStore.actions.list(filter, isAccumulateItems);
}

/**
 * @param data
 */
function logIt(data) {
  // console.log(data);
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  invoiceStore.mutations.resetStates();
  invoiceStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>
<style scoped>

</style>
